import React, { useCallback, useEffect } from "react"
import Portal from "../Portal/Portal"
import { useStaticQuery, graphql } from "gatsby"
import GravityFormForm from "gatsby-plugin-gravity-forms"
import "./recruiment-form.scss"
import "../CallbackForm/callbackForm.scss"
import logo from "../../images/logo.png"

function RecruitmentForm({ open, setOpen }) {
  const data = useStaticQuery(graphql`
    query formQuery5 {
      wp {
        ...GravityFormSettings
      }
      wpGfForm(databaseId: { eq: 5 }) {
        ...GravityFormFields
      }
    }
  `)
  const escFunction = useCallback(event => {
    if (event.key === "Escape") {
      setOpen(false)
    }
  }, [])
  useEffect(() => {
    document.addEventListener("keydown", escFunction, false)

    return () => {
      document.removeEventListener("keydown", escFunction, false)
    }
  }, [setOpen, escFunction])
  return (
    <Portal>
      <div className={`recruitment-form ${open && "open"}`}>
        <div
          className="close-button"
          onClick={() => {
            setOpen(false)
          }}
        >
          Q
        </div>
        <div
          className="overlay"
          onClick={() => {
            setOpen(false)
          }}
        ></div>
        <div className="container">
          <div className="form-wrapper">
            <div className="form-header">
              <div className="logo">
                <img src={logo} alt="Logo" />
              </div>
              <div className="form-title">
                <h3>Apply for this job</h3>
                <p>
                  If you think you've got what it takes, then we’d like to talk
                  to you. Please complete the following fields and attach your
                  CV to be considered for this vacancy.
                </p>
              </div>
            </div>
            <GravityFormForm data={data} />
          </div>
        </div>
      </div>
    </Portal>
  )
}

export default RecruitmentForm
