import React, { useState } from "react"
import { graphql } from "gatsby"
import parse from "html-react-parser"
import Layout from "../components/layout"
import Seo from "../components/seo"
import "../css/template/job-post.scss"
import "../components/Button/button.scss"
import RecruitmentForm from "../components/RecruimentForm/RecruimentForm"
import BackgroundImage from "gatsby-background-image"
import CallbackForm from "../components/CallbackForm/CallbackForm"
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import { ApolloProvider } from "@apollo/client"
import { client } from "../hooks/apolloClient"
const JobPostTemplate = ({ data }) => {
  const [openApply, setOpenApply] = useState(false)
  const [openCallback, setOpenCallback] = useState(false)
  const post = data?.post
  const image = getImage(post?.featuredImage?.node?.localFile)
  const bgImage = convertToBgImage(image)
  return (
    <ApolloProvider client={client}>
      <Layout>
        <div className="post-headline">
          <BackgroundImage className="post-cover" Tag="div" {...bgImage}>
            <div className="container">
              <div
                className="callback-button"
                onClick={() => {
                  setOpenCallback(!openCallback)
                }}
              >
                <p>Request a callback today »</p>
              </div>
              <div className="title-wrapper">
                {post?.title && <h1>{parse(post?.title)}</h1>}
              </div>
            </div>
            <CallbackForm open={openCallback} setOpen={setOpenCallback} />
          </BackgroundImage>
        </div>
        <div className="container main-post-wrapper">
          <div className="main-post-header">
            <p>
              Job Term:{" "}
              {post?.jobBoard?.employmentType?.map((type, index) =>
                index === post?.jobBoard?.employmentType?.length - 1 ? (
                  <span>{type}</span>
                ) : (
                  <span>
                    {type} {", "}
                  </span>
                )
              )}
            </p>
            <br />
            <p>Location: {post?.jobBoard?.location}</p>
            <br />
            <p>Posted: {post?.date}</p>
            <br />
            <p className="close-date">
              Closing Date: <span>{post?.jobBoard?.closingDate}</span>
            </p>
          </div>
          {post?.content && (
            <section className="main-post-content" itemProp="articleBody">
              {parse(post?.content)}
            </section>
          )}
          <div className="apply-job">
            <div className="social-share"></div>
            <div className="apply-button-wrapper">
              <div
                className="apply-button btn green-btn"
                onClick={() => {
                  setOpenApply(!openApply)
                }}
              >
                <a href="#">Apply for this job</a>
              </div>
            </div>
          </div>
          <RecruitmentForm open={openApply} setOpen={setOpenApply} />
        </div>
      </Layout>
    </ApolloProvider>
  )
}
export const Head = ({ data }) => (
  <Seo
    title={data?.seo?.seo?.title}
    description={data?.seo?.seo?.metaDesc}
    image={data?.seo?.featuredImage?.node?.sourceUrl}
    props={data?.seo?.seo}
  />
)
export default JobPostTemplate

export const pageQuery = graphql`
  query JobPostById($id: String!) {
    post: wpRecruitment(id: { eq: $id }) {
      id
      content
      jobBoard {
        closingDate
        employmentType
        location
      }
      title
      date(formatString: "DD MMMM YYYY")
      featuredImage {
        node {
          sourceUrl
          altText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
    seo: wpRecruitment(id: { eq: $id }) {
      featuredImage {
        node {
          sourceUrl
        }
      }
      seo {
        title
        metaDesc
        focuskw
        metaKeywords
        metaRobotsNofollow
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          srcSet
          sourceUrl
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        twitterTitle
        twitterDescription
        twitterImage {
          altText
          srcSet
          sourceUrl
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        canonical
        cornerstone
        schema {
          articleType
          pageType
          raw
        }
      }
    }
  }
`
